import React from 'react'

import Layout from 'components/Layout/Layout';
import Callback from 'components/Callback/Callback';

export default () => (
  <Layout>
    <Callback />
  </Layout>
);

