import React from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../../utils/userManager';

const Callback = () => {  
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => navigate('/')}
        errorCallback={error => {
          navigate('/page-2');
          console.error(error);
        }}
        >
        <div>Redirecting...</div>
      </CallbackComponent>
    );
}

export default connect()(Callback);